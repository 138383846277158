<template>
  <div class="survey mobile" style="padding-bottom: 60px;">
    <div class="scroll">
      <div class="survey-top">
        <img :src="staticUrl+'design/shoujiduan/pre.jpg'" />
        <div v-if="ruleForm.step==1">应收账款用于备料融资，减少资金占用</div>
      </div>
      <div>
        <Survey1
          v-if="ruleForm.step==1"
          v-model="ruleForm.detail"
          ref="form_check1"
          @step="addStep"
        ></Survey1>
        <Survey2
          v-if="ruleForm.step==2"
          v-model="ruleForm.detail.zjb"
          ref="form_check2"
          @step="addStep"
        ></Survey2>
        <Survey3
          v-if="ruleForm.step==3 || ruleForm.step==4"
          v-model="ruleForm.detail.ztl"
          ref="form_check3"
          @step="addStep"
        ></Survey3>
      </div>
    </div>
    <div v-if="this.ruleForm.step<4" class="survey-button flex flex-align-center flex-pack-center">
      <el-button
        v-if="ruleForm.step>=2"
        @click="back()"
        style="margin-right:20px"
        size="small"
        type="primary"
        plain
      >上一步</el-button>
      <el-button
        v-if="ruleForm.step==3 || ruleForm.step==4"
        @click="submitForm()"
        size="small"
        type="primary"
      >提交3/3</el-button>
      <el-button v-else @click="submitForm()" size="small" type="primary">下一步{{ruleForm.step}}/3</el-button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { STATIC_URL_PRE } from "@/config";
import { surveySubmit, surveyGet } from "@/service/mobile";
import Survey1 from "./Survey1";
import Survey2 from "./Survey2";
import Survey3 from "./Survey3";
export default {
  components: {
    Survey1,
    Survey2,
    Survey3,
  },
  data() {
    return {
      staticUrl: STATIC_URL_PRE,
      ruleForm: {
        step: 1,
        detail: {
          effect: [],
          effect_other: null,
          online_cs: null,
          monthSaleAr: null,
          monthBlAmt: null,
          needJr: false,
          needBl: false,
          zjb: null,
          ztl: null,
        },
      },
    };
  },
  async created() {
    document.title = "金融支持下的备料服务调研";
    this.refreshItems();
  },
  methods: {
    back() {
      this.ruleForm.step--;
    },
    addStep(data) {
      this.ruleForm.step = data;
      console.log(this.ruleForm.step);
      if (data >= 2 && data <= 4) this.submit(data);
    },
    goto(path) {
      this.$router.push(path);
    },
    refreshItems() {
      console.log("refreshItems");
      surveyGet()
        .then((rst) => {
          if (rst) {
            if (rst.step==4) {
              this.$router.push("/mobile/survey4");
            }
            this.ruleForm = {
              id: rst.id,
              step: rst.step,
              detail: {
                effect: rst.detail.effect,
                effect_other: rst.detail.effect_other,
                online_cs: rst.detail.online_cs,
                monthSaleAr: rst.detail.monthSaleAr,
                monthBlAmt: rst.detail.monthBlAmt,
                needJr: rst.detail.needJr,
                needBl: rst.detail.needBl,
                ztl: rst.detail.ztl,
                zjb: {
                  woodKind: {
                    id: null,
                    name: null,
                  },
                  count: null,
                  aa: {
                    cm14: {
                      count: null,
                      price: null,
                    },
                    cm16: {
                      count: null,
                      price: null,
                    },
                    cm18: {
                      count: null,
                      price: null,
                    },
                    cm20: {
                      count: null,
                      price: null,
                    },
                    cm22: {
                      count: null,
                      price: null,
                    },
                    cm24: {
                      count: null,
                      price: null,
                    },
                    other: {
                      var: null,
                      count: null,
                      price: null,
                    },
                  },
                  ab: {
                    cm14: {
                      count: null,
                      price: null,
                    },
                    cm16: {
                      count: null,
                      price: null,
                    },
                    cm18: {
                      count: null,
                      price: null,
                    },
                    cm20: {
                      count: null,
                      price: null,
                    },
                    cm22: {
                      count: null,
                      price: null,
                    },
                    cm24: {
                      count: null,
                      price: null,
                    },
                    other: {
                      var: null,
                      count: null,
                      price: null,
                    },
                  },
                  bj: {
                    cm14: {
                      count: null,
                      price: null,
                    },
                    cm16: {
                      count: null,
                      price: null,
                    },
                    cm18: {
                      count: null,
                      price: null,
                    },
                    cm20: {
                      count: null,
                      price: null,
                    },
                    cm22: {
                      count: null,
                      price: null,
                    },
                    cm24: {
                      count: null,
                      price: null,
                    },
                    other: {
                      var: null,
                      count: null,
                      price: null,
                    },
                  },
                  cb: {
                    cm14: {
                      count: null,
                      price: null,
                    },
                    cm16: {
                      count: null,
                      price: null,
                    },
                    cm18: {
                      count: null,
                      price: null,
                    },
                    cm20: {
                      count: null,
                      price: null,
                    },
                    cm22: {
                      count: null,
                      price: null,
                    },
                    cm24: {
                      count: null,
                      price: null,
                    },
                    other: {
                      var: null,
                      count: null,
                      price: null,
                    },
                  },
                },
              },
            };
            if (rst.detail.zjb && rst.detail.zjb.woodKind) {
              this.ruleForm.detail.zjb.woodKind = rst.detail.zjb.woodKind;
            }
            if (rst.detail.zjb && rst.detail.zjb.count) {
              this.ruleForm.detail.zjb.count = rst.detail.zjb.count;
            }
            if (rst.detail.zjb && rst.detail.zjb.specs.length>0) {
              this.ruleForm.detail.zjb = forInAssign(
                this.ruleForm.detail.zjb,
                rst.detail.zjb.specs
              );
            }
          }
          console.log("rst", rst);
          console.log("this.ruleForm", this.ruleForm);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    submitForm() {
      if (this.ruleForm.step == 1) {
        this.$refs.form_check1.submitForm1();
      } else if (this.ruleForm.step == 2) {
        this.$refs.form_check2.submitForm2();
      } else if (this.ruleForm.step == 3 || this.ruleForm.step == 4) {
        this.$refs.form_check3.submitForm3();
      }
    },
    submit(num) {
      let _item = JSON.parse(JSON.stringify(this.ruleForm));
      if (num >= 3) {
        _item.detail.zjb.woodKindId = _item.detail.zjb.woodKind.id;
        delete _item.detail.zjb.woodKind;
        let zjb = _item.detail.zjb;
        zjb.specs = [];
        zjb.aa = forInCheck(zjb.aa);
        zjb.ab = forInCheck(zjb.ab);
        zjb.bj = forInCheck(zjb.bj);
        zjb.cb = forInCheck(zjb.cb);
        if (JSON.stringify(zjb.aa) === "{}") {
            delete zjb.aa;
        } else {
            zjb.specs = zjb.specs.concat(tranZjbSpec("aa", zjb.aa));
            delete zjb.aa;
        }
        if (JSON.stringify(zjb.ab) === "{}") {
            delete zjb.ab;
        } else {
            zjb.specs = zjb.specs.concat(tranZjbSpec("ab", zjb.ab));
            delete zjb.ab;
        }
        if (JSON.stringify(zjb.bj) === "{}") {
            delete zjb.bj;
        } else {
            zjb.specs = zjb.specs.concat(tranZjbSpec("bj", zjb.bj));
            delete zjb.bj;
        }
        if (JSON.stringify(zjb.cb) === "{}") {
            delete zjb.cb;
        } else {
            zjb.specs = zjb.specs.concat(tranZjbSpec("cb", zjb.cb));
            delete zjb.cb;
        }
        _item.detail.zjb = zjb;
      }
      if (num == 4) {
        _item.detail.ztl.woodKindId = _item.detail.ztl.woodKind.id;
        delete _item.detail.ztl.woodKind;
      }
      console.log(JSON.stringify(_item));
      surveySubmit(_item).then((rst) => {
          this.item = rst;
          console.log(this.item);
          if (num == 4) {
            this.$message.success("提交成功");
            this.$router.push("/mobile/survey4");
          }
        }).catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
  },
};
function tranZjbSpec(type, frontSpecs) {
  let specModels = [];
  let specNames = Object.keys(frontSpecs);
  for (let specName of specNames) {
    let spec = frontSpecs[specName];
    if (spec.count && spec.price) {
      let specModel = {
        type: type,
        spec: specName,
        count: parseInt(spec.count),
        price: parseInt(spec.price),
        isOther: false,
      };
      if (specName == 'other') {
        specModel.spec = frontSpecs.other.var;
        specModel.isOther = true;
      }
      specModels.push(specModel);
    }
  }
  console.log(specModels);
  return specModels;
}
function forInAssign(obj, rst) {
    for (let i in rst) {
        if (rst[i].isOther) {
            obj[rst[i].type]['other'].var = rst[i].spec;
            obj[rst[i].type]['other'].count = rst[i].count;
            obj[rst[i].type]['other'].price = rst[i].price;
        } else {
            obj[rst[i].type][rst[i].spec].count = rst[i].count;
            obj[rst[i].type][rst[i].spec].price = rst[i].price;
        }
    }
  return obj;
}
// function forInAssign(obj, rst) {
//   for (let i in rst) {
//     if (rst[i].count && rst[i].price) {
//       obj[i] = rst[i];
//     }
//   }
//   return obj;
// }
function forInCheck(obj) {
  for (let i in obj) {
    if (!obj[i].count || !obj[i].price) {
      delete obj[i];
    }
  }
  return obj;
}
</script>
<style scoped src="../style.css"></style>
<style scoped>
.survey >>> input[type="number"] {
  padding: 0 0 0 15px;
}
</style>
<style scoped lang="less">
.survey-top {
  width: 94%;
  margin: 10px auto 10px;
  img {
    width: 100%;
  }
  > div {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f8f9ff;
    border: 1px solid #dbe1f6;
    font-size: 16px;
    color: #a9b6e1;
    margin-top: 10px;
  }
}
.ruleForm {
  width: 94%;
  margin: 0 auto;
  border: 1px solid #e6e6e6;
  padding: 0 10px;
}
.survey-button {
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px rgba(230, 230, 230, 1);
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
<template>
   <div>
        <div class="ruleForm">
            <div style="font-size:18px;font-weight:bold;color:#292929;">直条料</div>
            <el-form :model="ruleForm" :rules="rules" ref="formCheck3" class="login-form">
                <el-form-item style="margin-bottom:15px" prop="woodKind.id" label="您备料的主要木材种类">
                    <el-input style="opacity: 0;" placeholder="请选择" v-model="ruleForm.woodKind.id"></el-input>
                    <div @click="showPop()" class="selectInput flex">
                        <div class="flex-1">
                            <div v-if="ruleForm.woodKind.name">{{ruleForm.woodKind.name}}</div>
                            <div v-else style="color:#ccc">请选择</div>
                        </div>
                        <div>
                            <i class="iconfont icon-youjiantou"></i>
                        </div>
                    </div>
                </el-form-item>
                <div class="wrap">
                    <div class="wrap-title">主要用于哪一类产品</div>
                    <div v-if="ruleForm.products && ruleForm.products.length>0" class="wrap-list">
                        <div v-for="(item,idx) in ruleForm.products" :key="idx" class="wrap-item">
                            <div class="wrap-item-title flex">
                                <div class="flex-1">主要产品{{idx+1}}</div>
                                <div v-if="idx!=0" @click="delStyle(idx)">
                                    <i style="font-size:16px;color:#808080;border: 1px solid #808080;border-radius: 50%;" class="iconfont icon-Group"></i>
                                </div>
                            </div>
                            <div class="wrap-item-content">
                                <el-form-item style="margin-bottom:20px" :prop="'products.'+ idx +'.name'" :rules="rules.name" label="产品名称" label-width="80px">
                                    <el-input placeholder="请输入产品名称" v-model="item.name"></el-input>
                                </el-form-item>
                                <el-form-item style="margin-bottom:10px" :prop="'products.'+ idx +'.bjName'" :rules="rules.bjName" label="部件名称" label-width="80px">
                                    <el-input placeholder="请输入部件名称" v-model="item.bjName"></el-input>
                                </el-form-item>
                                <el-form-item style="margin-bottom:20px" label="规格尺寸">
                                    <el-col :xs="24">
                                        <el-form-item style="margin-bottom:20px" :prop="'products.'+ idx +'.size.len'" :rules="rules.len" label="长" label-width="80px">
                                            <div style="display:inline-block;width:calc(100% - 50px);margin-right:10px">
                                                <el-input placeholder="请输入长度" v-model.number="item.size.len" type="number"></el-input>
                                            </div>
                                            <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24">
                                        <el-form-item style="margin-bottom:20px" :prop="'products.'+ idx +'.size.width'" :rules="rules.width" label="宽" label-width="80px">
                                            <div style="display:inline-block;width:calc(100% - 50px);margin-right:10px">
                                                <el-input placeholder="请输入宽度" v-model.number="item.size.width" type="number"></el-input>
                                            </div>
                                            <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :xs="24">
                                        <el-form-item style="margin-bottom:0px" :prop="'products.'+ idx +'.size.height'" :rules="rules.height" label="高" label-width="80px">
                                            <div style="display:inline-block;width:calc(100% - 50px);margin-right:10px">
                                                <el-input placeholder="请输入高度" v-model.number="item.size.height" type="number"></el-input>
                                            </div>
                                            <div style="display:inline-block;font-size:12px;color:#808080;">cm</div>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item style="margin-bottom:20px" :prop="'products.'+ idx +'.count'" :rules="rules.count" label="数量" label-width="80px">
                                    <div style="display:inline-block;width:calc(100% - 50px);margin-right:10px">
                                        <el-input placeholder="请输入数量" v-model.number="item.count" type="number"></el-input>
                                    </div>
                                    <div style="display:inline-block;font-size:12px;color:#808080;">方/月</div>
                                </el-form-item>
                                <el-form-item style="margin-bottom:20px" :prop="'products.'+ idx +'.price'" :rules="rules.price" label="价格" label-width="80px">
                                    <div style="display:inline-block;width:calc(100% - 50px);margin-right:10px">
                                        <el-input placeholder="请输入价格" v-model.number="item.price" type="number"></el-input>
                                    </div>
                                    <div style="display:inline-block;font-size:12px;color:#808080;">元/方</div>
                                </el-form-item>
                            </div>
                        </div>
                        <div @click="addStyle()" class="add-product">
                            <span>+</span>
                            <span>继续添加产品</span>
                        </div>

                    </div>
                </div>
            </el-form>
            <ItemSelect :popFlag="popFlag" @selectItem="selectItem" @hidePop="popFlag=false"></ItemSelect>
        </div>

   </div>
</template>
<script>
    import _ from 'lodash';
    import { STATIC_URL_PRE } from '@/config';
    import ItemSelect from './ItemSelect.vue';
    export default {
        components: {
            ItemSelect
        },
        model: {
            prop: "ztl",
            event: "change"
        },
        props:['ztl'],
        data() {
            return {
                staticUrl:STATIC_URL_PRE,
                rules: {
                    [`woodKind.id`]: [{ required: true, message: '请选择备料的主要木材种类', trigger: 'blur' }],
                    name: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
                    bjName: [{ required: true, message: '请输入部件名称', trigger: 'blur' }],
                    len: [{ required: true, message: '请输入长度', trigger: 'blur' }],
                    width: [{ required: true, message: '请输入宽度', trigger: 'blur' }],
                    height: [{ required: true, message: '请输入高度', trigger: 'blur' }],
                    count: [{ required: true, message: '请输入数量', trigger: 'blur' }],
                    price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
                },
                ruleForm:{
                    woodKind:{
                        id:null,
                        name:null,
                    },
                    products:[
                        {
                            name:null,
                            bjName:null,
                            size:{
                                len:null,
                                width:null,
                                height:null,
                            },
                            count:null,
                            price:null,
                        }
                    ],
                },
                popFlag:false,
            }
        },
        watch:{
            ruleForm:{
                handler() {
                    this.onChange();
                },
                deep: true
            }
        },
        async created() {
            if (this.ztl) {
                this.ruleForm = this.ztl;
            }
        },
        methods: {
            onChange() {
                this.$emit('change', this.ruleForm);
            },
            showPop() {
                this.popFlag = true;
            },
            selectItem(item) {
                this.ruleForm.woodKind=item;
            },
            // 添加款式
            addStyle() {
                var obj = {
                    name:null, bjName:null,
                    size:{ len:null, width:null, height:null, },
                    count:null, price:null,
                };
                this.ruleForm.products.push(obj);
                console.log(this.ruleForm.products);
            },
            delStyle(index) {
                // this.$confirm('确认是否删除该产品?', '温馨提示', { confirmButtonText: '确定',
                // cancelButtonText: '取消', type: 'warning',
                // }).then(() => {
                    
                // });
                this.$message({ type: 'success', message: '删除成功!' });
                this.ruleForm.products.splice(index,1);
            },
            submitForm3() {
                this.$refs['formCheck3'].validate((valid) => {
                    if (valid) {
                        this.$emit("step",4);
                    }
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped lang="less">
.ruleForm {
    width: 94%;
    margin: 0 auto;
    .selectInput {
        position: absolute;
        left: 0;
        top: 40px;
        font-size: 14px;
        width: 100%;
        background-color: #FFF;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #606266;
        padding: 0 15px;
        >div{
            height: 38px;
            line-height: 38px;
            i {
                display: inline-block;
                font-size: 16px;
                transform:rotate(90deg);
                -webkit-transform:rotate(90deg);
                position: relative;
                top: 1px;
            }
        }
    }
}
.wrap {
    .wrap-title {
        padding-bottom: 10px;
    }
    .wrap-list {
        .wrap-item {
            padding: 10px;
            background: #ffffff;
            border: 1px solid #E6E6E6;
            margin-bottom: 10px;
            .wrap-item-title {
                font-size: 14px;
                color: #5074EE;
                line-height: 20px;
                padding-bottom: 10px;
            }
            .wrap-item-content {
                width: 100%;
                padding: 10px;
                border: 1px solid #EBEEF5;
            }
        }
        .add-product {
            width: 150px;
            text-align: center;
            line-height: 36px;
            margin: 0 auto 0;
            border: 1px solid #5074EE;
            border-radius: 18px;
            box-sizing: border-box;
            cursor: pointer;
            span {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                color: #5074EE;
            }
            span:first-child {
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 14px;
                border: 1px solid #5074EE;
                box-sizing: border-box;
                border-radius: 50%;
                margin-right: 5px;
            }
        }
    }
}
</style>
<style scoped>
    /* .wrap-list >>> .el-collapse-item__content {
        padding-bottom: 10px;
    }
    div>>> .el-collapse-item__header {
        color: #5074EE;
        position: relative;
    }
    div>>> .el-collapse-item__header i {
        color: #4D4D4D;
    } */
</style>